import React from "react";
import { PageLayout } from "~components/templates/page-layout";
import { withContext } from "~context";
import * as styles from "./index.module.scss";

const Component: React.FC = () => {
  return (
    <PageLayout
      paddingPattern="larger-than-header"
      meta={{ title: "アンチハラスメントポリシー" }}
    >
      <div className={styles.AntiHarassment}>
        <h1>アンチハラスメントポリシー</h1>
        <p>
          株式会社Flatt
          Securityが主催・運営をするイベントでは、以下のとおりアンチハラスメントポリシーを策定しております。当ポリシーは皆様が安心して集える場を維持するためにあり、参加者はハラスメントに該当する行為の禁止を遵守するものとします。登壇者やスポンサー、運営スタッフもこのポリシーの対象となります。
        </p>

        <h2>ハラスメントに該当する事項</h2>
        <p>
          {`・ジェンダー、性自認やジェンダー表現、性的指向、障碍、容貌、体型、人種、民族、年齢、宗教あるいは無宗教についての不適切なコメントをすること
            ・他人の容姿、美酷に言及すること
            ・公共のスペースで性的な画像を掲示すること
            ・脅迫、ストーキング、付きまとい。または、それらを計画すること
            ・不適切な身体的接触
            ・発表や他のイベントを継続的に妨害すること
            ・不快な性的アトラクション
            ・上記以外の事項であっても、参加者が不快、不安に感じる発言や行動はハラスメントとみなされます
            ・以上のような行為を推奨したり、擁護したりすること`}
        </p>

        <h2>ハラスメントを受けた場合、判断に迷った場合</h2>
        <p>
          ハラスメントを受けた場合、見かけた場合、もしくはハラスメントかどうか判断に迷った場合は
          <a href="mailto:anti-harassment@flatt.tech">
            anti-harassment@flatt.tech
          </a>
          {`へお問い合わせください。
            我々運営スタッフは、ハラスメントに直面している参加者の心理的/身体的な安全を、全力で確保するように努めます。また、ハラスメント行為をやめるように要請された参加者は、運営スタッフの指示に直ちに従ってください。`}
        </p>

        <h2>参考</h2>
        <p>
          当ポリシーは、以下のガイドラインやアンチハラスメントポリシーを参考に策定いたしました。
          <br />・
          <a href="https://rubykaigi.org/2018/policies">
            Policies - RubyKaigi 2018
          </a>
          <br />・<a href="http://25.ruby.or.jp/coc.ja.html">Ruby25 | CoC</a>
          <br />・
          <a href="http://jxug.org/policy.html">JXUG : コミュニティポリシー</a>
          <br />・
          <a href="https://jphacks.github.io/2017-guideline/anti-harassment/">
            アンチハラスメントポリシー - JPHACKS2017 参加者向けガイドライン
          </a>
          <br />・
          <a href="https://gist.github.com/JunichiIto/7a080f1cfb0ae27ef600c14b94a02db7">
            TokyoGirls.rb アンチハラスメントポリシー
          </a>
          <br />
        </p>
      </div>
    </PageLayout>
  );
};

export default withContext(Component);
